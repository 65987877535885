import HomeScreen from "./Page/HomeScreen";



function App() {
  return (
    <div className="area-blot">
       <HomeScreen />
    </div>
  );
}

export default App;