import React, { useEffect, useState } from 'react'
import './modelling.css';
import axios from 'axios';
import playVideo from "../assets/playvideo.png";

function HomeScreen() {
    const [modalClass, setModalClass]                                            = useState("");    
    const [isModalActive, setModalActive]                                        = useState(false);

    const [sketchModalClass, setSketchModalClass]                                = useState("");
    const [isScketchModalActive, setIsScketchModalActive]                        = useState(false);

    const [listVideos, setVideosList]                                            = useState([]);
    const [formType, setFormType]                                                = useState("");

    const [currentVideoView, setCurrentVideoView]                                = useState();

    //Form Details
    const [personName, setPersonName]                                            = useState("");
    const [personSurname, setPersonSurname]                                      = useState("");
    const [personEmail, setPersonEmail]                                          = useState("");
    const [personPracticeNo, setPersonPracticeNo]                                = useState("");
    const [personProfession, setPersonProfession]                                = useState("");
    const [personPhoneNumber, setPersonPhoneNumber]                              = useState("");
    const [pharmacyTitle, setPharmacyTitle]                                        = useState("");
    const [pharmacyStreet, setPharmacyStreet]                                      = useState("");
    const [pharmacyTown, setPharmacyTown]                                          = useState("");
    const [pharmacyCity, setPharmacyCity]                                          = useState("");
    const [pharmacyZipCode, setPharmacyZipCode]                                    = useState("");
    const [provinceItem, setProvinceItem]                                          = useState("Eastern Cape");

    const [honorificTitle, setHonorificTitle]                                      = useState("Mr");
    const [processing, setProcessing]                                              = useState(false);
    const CONSTANTAPI = "https://rescurescripts-51d8d1272bab.herokuapp.com/api";

    /*
    useEffect(() => {
        if(pharmacyTitle.length > 3){
          
        }
    },[pharmacyTitle])
    */

   useEffect(() => {
       //pharmContentFetch()
   },[])

    useEffect(() => {
        if(modalClass === "four out"){            
            setTimeout(function () {
                setModalClass("");
            }, 2000);
        }
    },[modalClass])

  const openModal = () => {
    setModalClass("four");
    setModalActive(true);
    setFormType("view-form");
  };

  const closeModal = () => {
    setModalClass("four out");
    setModalActive(false);
  };

  useEffect(() => {
    handleLoadingInitialVideos();
  },[])

  const handleLoadingInitialVideos = async () => {
    try{

        const tokenItem = {
            "token" : "gtusrhisjdfldgfdg1d664jonSonw3"
        }
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: CONSTANTAPI +'/videos/list/topharm/client/token',
            headers: { 
                'Content-Type': 'application/json'
                
            },
            data: tokenItem
        };
        
        const infoVideos = await axios.request(config);
       // console.log(infoVideos);
        setVideosList(infoVideos.data)
        
    }catch(err){
        console.log(err);
    }
  }

  const handlePharmacyDetails = async (e) => {
    e.preventDefault();
   
    const details = {
        "name"              : personName,
        "surname"           : personSurname,
        "email"             : personEmail,
        "profession"        : personProfession,
        "phone"             : personPhoneNumber,
        "practicenumber"    : personPracticeNo,
        "pharmtitle"        : pharmacyTitle,
        "pharmstreet"       : pharmacyStreet,
        "pharmtown"         : pharmacyTown,
        "pharmcity"         : pharmacyCity,
        "pharmzipcode"      : pharmacyZipCode,
        "province"          : provinceItem,
        "group"             : "The Local Choice",
        "grouptype"         : "FFRANCHISE",
        "honorific"         : honorificTitle,
    }

    try{
       

        setProcessing(true);
       const res = await axios.put(CONSTANTAPI + "/users/person/creation", details);
    
    
        setPersonName("");
        setPersonSurname("");
        setPersonEmail("");
        setPersonPracticeNo("");
        setPersonProfession("");
        setPersonPhoneNumber("");
        setPharmacyTitle("");
        setPharmacyStreet("");
        setPharmacyTown("");
        setPharmacyCity("");
        setPharmacyZipCode("");
        setProvinceItem("");

       setProcessing(false);
    }catch(err){
        console.log(err);
        setProcessing(false);
    }
    
  }

  const handleViewThisVideo = async (video) => {

    //console.log(video);
    setCurrentVideoView(video)
    setFormType("view-video");
    setIsScketchModalActive(true);
  }

  const closingHandle = async () => {
    setIsScketchModalActive(false);
    setCurrentVideoView(null);
  }

  const handleClickInsideModal = (e) => {
    e.stopPropagation(); // Prevents the event from propagating to the modal-container
  };

  ////////////////////////////////////////////////
  const pharmContentFetch = async () => {
    try{

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://mytlc.co.za/wp-json/wp/v2/pharmacy?orderby=title&order=asc&per_page=200',
          };

        const pharmacyList = await axios.request(config);
        console.log(pharmacyList);

    } catch(err){
        console.log(err);
    }
  }
  return (
    <div className={isModalActive ? "modal-active" : ""}>
      <div id="modal-container" className={modalClass} onClick={closeModal}>
        <div className="modal-background">
          <div className="modal">
             <div className="form-outer-box" onClick={handleClickInsideModal}>            
                <h2>Add Details</h2>
                <div className="hd-form">
                       <form onSubmit={handlePharmacyDetails}>
                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                   <div className="txt-label">Name<span className="aster">*</span>:</div>
                                      <input type="text" className="form-control" placeholder="Name" onChange={(e) => setPersonName(e.target.value)} required/>
                                    </div>
                                </div>
                                <div className="side-item">
                                   <div className="form-group">
                                   <div className="txt-label">Surname<span className="aster">*</span>:</div>
                                      <input type="text" className="form-control" placeholder="Surname" onChange={(e) => setPersonSurname(e.target.value)} required/>
                                    </div>
                                </div>
                            </div>
                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                     <div className="txt-label">Email Address<span className="aster">*</span>:</div>
                                      <input type="email" className="form-control" placeholder="Email Address" onChange={(e) => setPersonEmail(e.target.value)} required/>
                                    </div>
                                </div>
                                <div className="side-item">
                                   <div className="form-group">
                                      <div className="txt-label">Profession:</div>
                                      <input type="text" className="form-control" placeholder="Profession" onChange={(e) => setPersonProfession(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                     <div className="txt-label">Phone<span className="aster">*</span>:</div>
                                      <input type="text" className="form-control" placeholder="Phone Number" onChange={(e) => setPersonPhoneNumber(e.target.value)} required/>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="form-group">
                                        <div className="txt-label">Practice No<span className="aster">*</span>:</div>
                                        <input type="text" className="form-control" placeholder="Practice No" onChange={(e) => setPersonPracticeNo(e.target.value)} required/>
                                    </div>                    
                                </div>
                            </div>                
                            
                            <div className="form-group">
                                <div className="txt-label">Pharmacy Title<span className="aster">*</span>:</div>
                                <input type="text" className="form-control" placeholder="Pharmacy Title" onChange={(e) => setPharmacyTitle(e.target.value)}  required/>
                            </div>

                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                     <div className="txt-label">Street<span className="aster">*</span>:</div>
                                      <input type="text" className="form-control" placeholder="Street" onChange={(e) => setPharmacyStreet(e.target.value)} required/>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="form-group">
                                        <div className="txt-label">Town<span className="aster">*</span>:</div>
                                        <input type="text" className="form-control" placeholder="Town" onChange={(e) => setPharmacyTown(e.target.value)} required/>
                                    </div>                    
                                </div>
                            </div>  

                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                     <div className="txt-label">City<span className="aster">*</span>:</div>
                                      <input type="text" className="form-control" placeholder="City" onChange={(e) => setPharmacyCity(e.target.value)} required/>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="form-group">
                                        <div className="txt-label">Zip Code<span className="aster">*</span>:</div>
                                        <input type="text" className="form-control" placeholder="Zip Code" onChange={(e) => setPharmacyZipCode(e.target.value)} required/>
                                    </div>                    
                                </div>
                            </div>   
                        
                           

                            <div className="flexme">
                                <div className="side-item">
                                   <div className="form-group">
                                    <div className="txt-label">Province<span className="aster">*</span>:</div>
                                        <select className="form-control" onChange={(e) => setProvinceItem(e.target.value)}>
                                            <option value="Eastern Cape">Eastern Cape</option>
                                            <option value="Free State">Free State</option>
                                            <option value="Gauteng">Gauteng</option>
                                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                            <option value="Limpopo">Limpopo</option>
                                            <option value="Mpumalanga">Mpumalanga</option>
                                            <option value="Northern Cape">Northern Cape</option>
                                            <option value="North West">North West</option>
                                            <option value="Western Cape">Western Cape</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="form-group">
                                        <div className="txt-label">Person Title/Honorifice<span className="aster">*</span>:</div>
                                        <select className="form-control" onChange={(e) => setHonorificTitle(e.target.value)}>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Dr">Dr</option>
                                            <option value="Prof">Prof</option>
                                            <option value="Sir">Sir</option>
                                            <option value="Madam">Madam</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>                    
                                </div>
                            </div>  
                            <div className="form-group">
                                <button className="button btn-overview " disabled={processing}>Submit</button>
                            </div>
                       </form>
                       {
                         processing && (
                            <div className="img-process">
                                <img src="https://api.picnick.co.za/assets/smallprocess.gif" className="img-proc" />
                            </div>
                         )
                       }
                </div>
             </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="video-outer-area">
        <video autoPlay muted loop className="video-opensection">
            <source src="https://resqscripts.co.za/videos/About/mediback.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <div className="content-form-time">
                <div className="content-explain">
                    <h3 className="poppins-black">ResQ-Scripts</h3>
                    <div className="content-explain-text">
                        Pharmacies can add the specific details that are required in order to be legible to login and utilize the application to 
                        create emergency scripts under emergency situation.
                    </div>                        
                </div>
            </div>
        </div>
        
        {
            /***
             * <button id="four" className="button btn-overview positonfix" onClick={openModal}>
                        Pharmacy Registration
                </button>
             * 
             */
        }
        <h1 className="title-section">How to Videos</h1>
        <div className="vid-options-view">
            <div className="pd20 vid-options-intro">
            <p>
                As a proud member of The Local Choice franchise, we are dedicated to supporting pharmacists like you with cutting-edge technology and resources. 
                Our mission is to enhance patient care and streamline pharmacy operations, ensuring that you can focus on what matters most - providing exceptional healthcare 
                services to your community.
            </p>
            <h4>Introducing ResQ-Script: Revolutionizing Emergency Prescription Dispensing</h4>
            <p>
                We are excited to introduce ResQ-Script, a groundbreaking platform that enables pharmacists to dispense one-month emergency scripts with governmental approval. 
                This innovative solution addresses critical situations where timely access to medication is crucial.
            </p>
            <p>  
                On this page, you'll find a collection of informative videos that delve into the features and benefits of ResQ-Script. 
                Discover how this platform can transform your pharmacy's response to emergency situations and improve patient outcomes.
            </p>
            </div>  
            <div className="pd20 list-vids">
                    {
                        listVideos &&
                        listVideos.length > 0 && (
                            <div className="video-oppotunity">
                                {
                                    listVideos.map((video, indx) => {
                                        return <div className="box-beast" key={indx}>
                                            <div className="info-box-beast">
                                                <h2>{video.title}</h2>
                                                <div className="desc-strip poppins-thin">
                                                    {video.description}
                                                </div>
                                                <img id="six" src={playVideo} className="video-play" onClick={() => handleViewThisVideo(video)}/>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        )
                    }
            </div> 
        </div>      
      </div>
      {
            isScketchModalActive && (
                <div className="video-dialogue">
                    <div className="video-dialogue-item">
                     <button className="btn-closing" onClick={() => closingHandle()}>X</button>
                     <video autoPlay loop className="video-viewing">
                        <source src={currentVideoView.videourl} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                </div>
            )
      }
    </div>
  )
}

export default HomeScreen